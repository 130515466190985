import './barraca25.css'
import ImgProduto from '../../../assets/produtos/img-produtos-barra-ca-25-1-min.png'

function BarraCA25(){
  return(
    <div className="product-content">
      <div className='product-header'>
        <img src={ImgProduto} alt='imagem-produto'/>
        <div>
          <h1>Barra CA-25</h1>
          <p>A Barra CA-25 fornecida pela Aramebras são produzidas em usinas através do processo de laminação a quente e conforme a norma ABNT NBR 7480, proporcionando maior segurança à estrutura.</p>
        </div>
      </div>
      <div className='product-detail'>
        <div>
          <h2>Benefícios do Produto</h2>
          <p>Maior flexibilidade no aço;</p>
          <p>Boa soldabilidade.</p>
        </div>
        <div>
          <h2>Aplicações Recomendadas</h2>
          <p>Pisos industriais;</p>
          <p>Pavimentos de concreto;</p>
          <p>Armaduras pré-moldadas;</p>
          <p>Pavimentos aeroportuários, entre outros.</p>
        </div>
        <div>
          <h2>Especificações</h2>
          <p>8,00mm (5/16);</p>
          <p>10,00mm (3/8);</p>
          <p>12,50mm (1/2);</p>
          <p>16,00mm (5/8);</p>
          <p>20,00mm (3/4);</p>
          <p>25,00mm (1”).</p>
        </div>
      </div>
      <div className='budget-button'>
        <a href="/contato">Solicitar orçamento</a>
      </div>
    </div>
  )
}

export default BarraCA25;