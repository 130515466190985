import './home.css'
import ImgHome from '../../assets/img-home-1.png'
import ImgHome2 from '../../assets/img-home-2.png'
import ImgHome3 from '../../assets/img-home-3.png'
import { Link } from 'react-router-dom';
import Slider from '../../components/Slider';

function Home(){
  return(
    <div className='home'>
      <div className='home-wrapper'>
        <p>Um mundo feito de aço.</p>
      </div>
      <div className='content'>
        <div>
          <h2>Tradição que leva mais de 30 anos de know-how.</h2>
          <p>A Aramebras produz soluções no segmento do aço e atende todo o Brasil.</p>
          <p>Consolidada no mercado, nosso foco de atendimento é a construção civil e os setores industriais.</p>
          <p>Nós prezamos por uma produção de qualidade, disponibilidade para os clientes e agilidade em todos os processos até a entrega final.</p>
          <p>Adaptamos nossas soluções conforme sua necessidade.</p>
          <Link className='button-home' to='/sobre'>Saiba mais</Link>
        </div>
        <img className='img-home-1' src={ImgHome} alt='imagem-inicio'/>
      </div>
      <Slider/>
      <div className='products-button'>
        <a className='to-products' href='/produtos'>Conheça nossos produtos:</a>
      </div>
      <div className='location'>
        <div className='location-content'>
          <h1>Localização estratégica.</h1>
          <p>Com distribuição em larga escala atendemos a sua necessidade com agilidade e comprometimento.</p>
          <a className='to-contact' href='/contato'>Solicite seu orçamento</a>
        </div>
        <img className='img-home-2' src={ImgHome2} alt='img-home-2'/>
      </div>
      <div className='img-footer-home'>
        <img className='img-home-3' src={ImgHome3} alt='img-home-3'/>
      </div>
    </div>
  )
}

export default Home;