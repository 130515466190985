import './sobre.css'
import ImgSobre from '../../assets/img-home-1.png'
import ImgEstrutura from '../../assets/img-sobre.png'

function Sobre(){
  return(
    <div>
      <div className='sobre'>
        <div>
          <h2>Tradição que leva mais de 30 anos de know-how.</h2>
          <p>A Aramebras produz soluções no segmento do aço e atende todo o Brasil.</p>
          <p>Consolidada no mercado, nosso foco de atendimento é a construção civil e os setores industriais.</p>
          <p>Nós prezamos por uma produção de qualidade, disponibilidade para os clientes e agilidade em todos os processos até a entrega final.</p>
          <p>Adaptamos nossas soluções conforme sua necessidade.</p>
        </div>
        <img className='img-home-1' src={ImgSobre} alt='imagem-inicio'/>
      </div>
      <div className='sobre-estrutura'>
        <img className='img-estrutura' src={ImgEstrutura} alt='imagem-inicio'/>
        <div className='sobre-estrutura-content'>
          <h1>Nossa estrutura</h1>
          <p>A Aramebras é uma empresa 100% nacional que possui know-how de 30 anos no mercado.</p>
          <p>Nesse longo caminho percorrido, nós passamos por muitos desafios e adaptações. Apostamos em inovação para aprimorar nossa produção, com atenção ao uso de recursos naturais e às práticas sustentáveis.</p>
          <p>Asseguramos os altos padrões de qualidade do mercado em um laboratório próprio, um dos nossos grandes investimentos em inovação.</p>
        </div>
      </div>
      <div className='sobre-filosofia'>
        <h1>Nossa Filosofia</h1>
        <div className='sobre-filosofia-content'>
          <div className='missao'>
            <div className='title-missao'>
              <h1>Missão</h1>
            </div>
            <div className='content-missao'>
              <p>Nossa missão é levar soluções eficientes, prezando pela produção e distribuição de maneira sustentável.</p>
            </div>
          </div>
          <div className='visao'>
            <div className='title-visao'>
              <h1>Visão</h1>
            </div>
            <div className='content-visao'>
              <p>Queremos nos tornar uma das maiores referências no segmento do aço da região sul do país, baseando nossos processos em inovação e tecnologia.</p>
            </div>
          </div>
          <div className='valores'>
            <div className='title-valores'>
              <h1>Valores</h1>
            </div>
            <div className='content-valores'>
              <p>• Responsabilidade socioambiental.</p>
              <p>• Comprometimento com o cliente.</p>
              <p>• Atendimento ágil.</p>
              <p>• Geração de valor para os consumidores.</p>
              <p>• Desenvolvimento profissional da equipe.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Sobre;