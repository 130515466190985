import './arameri.css'
import ImgProduto from '../../../assets/produtos/img-produtos-arame-r-i-1-min.png'

function ArameRI(){
  return(
    <div className="product-content">
      <div className='product-header'>
        <img src={ImgProduto} alt='imagem-produto'/>
        <div>
          <h1>Arame Recozido Industrial</h1>
          <p>O arame recozido industrial Aramebras é fabricado com aço de baixo teor de carbono, que o deixa mais maleável e fácil de utilizar nas dobras e torções. O arame é produzido conforme a norma ABNT NBR 5589.</p>
        </div>
      </div>
      <div className='product-detail'>
        <div>
          <h2>Benefícios do Produto</h2>
          <p>Facilidade de manuseio nas amarrações;</p>
          <p>Boa maleabilidade;</p>
          <p>Ganho de produtividade;</p>
          <p>Redução de perdas.</p>
        </div>
        <div>
          <h2>Aplicações Recomendadas</h2>
          <p>Fixações de armaduras de concreto armado;</p>
          <p>Amarrações em peças industriais.</p>
        </div>
        <div>
          <h2>Especificações</h2>
          <p>BWG 8 (4,18mm)</p>
          <p>BWG 10 (3,40mm)</p>
          <p>BWG 12 (2,76mm)</p>
          <p>BWG 14 (2,10mm)</p>
          <p>BWG 16 (1,65mm)</p>
          <p>BWG 18 (1,24mm)</p>
        </div>
      </div>
      <div className='budget-button'>
        <a href="/contato">Solicitar orçamento</a>
      </div>
    </div>
  )
}

export default ArameRI;