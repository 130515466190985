import './ca60r.css'
import ImgProduto from '../../../assets/produtos/img-produtos-ca-60-r-1-min.png'

function CA60R(){
  return(
    <div className="product-content">
      <div className='product-header'>
        <img src={ImgProduto} alt='imagem-produto'/>
        <div>
          <h1>CA-60 Aramebras</h1>
          <p>O CA-60 Aramebras é fabricado com nervuras, conferindo melhor aderência ao concreto. Possuem certificação ABNT NBR 7480, garantindo máxima segurança para construção de estruturas de concreto armado.</p>
        </div>
      </div>
      <div className='product-detail'>
        <div>
          <h2>Benefícios do Produto</h2>
          <p>Torna a estrutura de concreto mais leve;</p>
          <p>Possui alta resistência;</p>
          <p>Maior facilidade de solda.</p>
        </div>
        <div>
          <h2>Aplicações Recomendadas</h2>
          <p>Telas eletrossoldadas;</p>
          <p>Colunas;</p>
          <p>Estribos;</p>
          <p>Treliças.</p>
        </div>
        <div>
          <h2>Especificações</h2>
          <p>Comercializado em rolos de ±180kg, estocador de ±900kg e bobinas de 1000kg a 1500kg, nos seguintes diâmetros:</p>
          <p>3,40mm; 3,80mm; 4,20mm; 4,60mm; 5,00mm; 5,50mm; 6,00mm; 7,00mm; 8,00mm; 9,50mm; 10,00mm.</p>
        </div>
      </div>
      <div className='budget-button'>
        <a href="/contato">Solicitar orçamento</a>
      </div>
    </div>
  )
}

export default CA60R;