import './aramebtc.css'
import ImgProduto from '../../../assets/produtos/img-produtos-arame-btc-4-min.png'

function ArameBTC(){
  return(
    <div className="product-content">
      <div className='product-header'>
        <img src={ImgProduto} alt='imagem-produto'/>
        <div>
          <h1>Arame BTC</h1>
          <p>O Arame BTC da Aramebras é produzido de aço de baixo teor de carbono. Pode ser fornecido com aço de SAE 1006 a 1012, conforme a norma ABNT NBR 5589, que garante a característica mecânica e superficial do produto acabado. O BTC possui superfície clara ou semi polida e pode receber acabamento em pintura, esmaltação e zincagem.</p>
        </div>
      </div>
      <div className='product-detail'>
        <div>
          <h2>Benefícios do Produto</h2>
          <p>Boa soldabilidade;</p>
          <p>Fácil aplicação;</p>
          <p>Excelente conformação mecânica;</p>
          <p>Excelente acabamento superficial.</p>
        </div>
        <div>
          <h2>Aplicações Recomendadas</h2>
          <p>Barra roscada;</p>
          <p>Prego;</p>
          <p>Condensador de geladeira;</p>
          <p>Grampos;</p>
          <p>Carrinho de supermercado;</p>
          <p>Gôndolas;</p>
          <p>Indústria de artefatos, entre outros;</p>
        </div>
        <div>
          <h2>Especificações</h2>
          <p>Fabricamos nos diâmetros entre 1,10mm a 12mm, de acordo com a necessidade do cliente;</p>
          <p>Entregue em rolos (+- 180kg), carretel (de 1,10mm a 1,40mm, com +- 650kg) ou estocador (+-900kg).</p>
        </div>
      </div>
      <div className='budget-button'>
        <a href="/contato">Solicitar orçamento</a>
      </div>
    </div>
  )
}

export default ArameBTC;